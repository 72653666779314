import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Icon, IconFont, UserCircleIcon } from 'tdesign-icons-vue';
import { MessagePlugin } from 'tdesign-vue';
import { reqLogOut } from '@/api';
export default {
  name: 'Home',
  components: {
    Icon,
    IconFont,
    UserCircleIcon
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  data() {
    return {
      collapsed: false,
      MessagePlugin,
      userInfo: {},
      options: [{
        content: '个人中心',
        value: 1,
        onclick: () => this.MessagePlugin.success('跳转个人中心界面')
      }, {
        content: '退出登录',
        value: 2,
        onClick: () => this.handleLogout()
      }]
    };
  },
  methods: {
    handleLogout() {
      reqLogOut().then(res => {
        if (res.code == 200) {
          localStorage.removeItem("userInfo");
          this.$router.push("/login");
          this.MessagePlugin.success("退出成功");
        } else {
          this.MessagePlugin.error(res.msg);
        }
      });
    }
  }
};