import Vue from 'vue'
import App from './App.vue'
import router from './router'

// import VueCompositionAPI from '@vue/composition-api';
// // 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';
// Vue.use(VueCompositionAPI); // 必须是项目的第一个 use

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
