import requests from './request'
import axios from 'axios'
// 这里统一接口管理

/**
 * 
 * @returns 获取所有用户
 */
export const reqGetUser = ()=>requests({
    url:'/user',
    method:'get',
})


/**
 * 
 * @returns 获取分页用户
 */
export const reqGetPage = (params)=>requests({
    url:'/user/page',
    method:'get',
    params
})


/**
 * 管理员登录
 * @param {*} data 
 * @returns 
 */
export const reqadminLogin = (data)=>requests({
    url:'/common/adminlogin',
    method:'post',
    data
})

/**
 * 添加用户
 * @param {*} data 
 * @returns 
 */
export const reqAddUser = (data)=>requests({
    url:'/user',
    method:'post',
    data
})

export const reqSetStatus = (data)=>requests({
    url:'/user/status',
    method:'post',
    data
})

export const reqDeleteUser = (id)=>requests({
    url:`/user/${id}`,
    method:'delete',
})

export const reqEditUser = (data)=>requests({
    url:`/user`,
    method:"put",
    data
})

export const reqCheckLogin = ()=>requests({
    url:'/user/checklogin',
    method:'get',
})

export const reqLogOut = ()=>requests({
    url:'/common/logout',
    method:'get',
})

export const reqGetOrder = (params)=>requests({
    url:'/order/page',
    method:'get',
    params
})

export const reqDeleteOrder = (id)=>requests({
    url:`/order/${id}`,
    method:'delete',
})

export const reqGetCommodity = (params)=>requests({
    url:'/commodity/page',
    method:'get',
    params
})

export const reqAddCommodity = (data)=>requests({
    url:'/commodity',
    method:'post',
    data
})

export const reqDeleteCommodity = (id)=>requests({
    url:`/commodity/${id}`,
    method:'delete',
})

export const reqEditCommodity = (data)=>requests({
    url:`/commodity`,
    method:"put",
    data
})

export const reqAddRecommender = (data)=>requests({
    url:'/recommender',
    method:'post',
    data
})

export const reqGetRecommender = (params)=>requests({
    url:'/recommender/page',
    method:'get',
    params
})

export const reqEditRecommender = (data)=>requests({
    url:`/recommender`,
    method:"put",
    data
})

export const reqDeleteRecommender = (id)=>requests({
    url:`/recommender/${id}`,
    method:'delete',
})
