import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children:[
      {
        path:'/',
        name:'usermanage',
        component: () => import('../views/UserManage.vue')
      },
      {
        path:'/usermanage',
        name:'usermanage',
        component: () => import('../views/UserManage.vue')
      },
      {
        path:"/ordermanage",
        name:"ordermanage",
        component: () => import('../views/OrderManage.vue')
      },
      {
        path:"/commoditymanage",
        name:"commoditymanage",
        component: () => import('../views/CommodityManage.vue')
      },
      {
        path:"/recommender",
        name:"recommender",
        component: () => import('../views/Recommender.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
