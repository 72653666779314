import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reqadminLogin, reqCheckLogin } from '@/api/index';
import { MessagePlugin } from 'tdesign-vue';
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      MessagePlugin
    };
  },
  mounted() {
    reqCheckLogin().then(res => {
      if (res.code == 200) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$router.push("/home");
      }
    });
  },
  methods: {
    login() {
      reqadminLogin(this.form).then(res => {
        if (res.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$router.push("/home");
        } else {
          this.MessagePlugin.error(res.msg);
        }
      });
    }
  }
};