/* unplugin-vue-components disabled */import { Content as __unplugin_components_12 } from 'tdesign-vue';import 'tdesign-vue/es/layout/style';
import { MenuItem as __unplugin_components_11 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { MenuItem as __unplugin_components_10 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { MenuItem as __unplugin_components_9 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { MenuItem as __unplugin_components_8 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { Menu as __unplugin_components_7 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { Aside as __unplugin_components_6 } from 'tdesign-vue';import 'tdesign-vue/es/layout/style';
import { Layout as __unplugin_components_5 } from 'tdesign-vue';import 'tdesign-vue/es/layout/style';
import { Button as __unplugin_components_4 } from 'tdesign-vue';import 'tdesign-vue/es/button/style';
import { Dropdown as __unplugin_components_3 } from 'tdesign-vue';import 'tdesign-vue/es/dropdown/style';
import { HeadMenu as __unplugin_components_2 } from 'tdesign-vue';import 'tdesign-vue/es/menu/style';
import { Header as __unplugin_components_1 } from 'tdesign-vue';import 'tdesign-vue/es/layout/style';
import { Layout as __unplugin_components_0 } from 'tdesign-vue';import 'tdesign-vue/es/layout/style';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(__unplugin_components_0,[_c(__unplugin_components_1,{staticStyle:{"position":"fixed","top":"0","left":"0","z-index":"2","width":"100%"},attrs:{"height":"54px"}},[_c(__unplugin_components_2,{staticStyle:{"border":"1px solid #E8E8E8"},attrs:{"value":"item1"},scopedSlots:_vm._u([{key:"operations",fn:function(){return [_c(__unplugin_components_3,{attrs:{"options":_vm.options,"minColumnWidth":88}},[_c(__unplugin_components_4,{attrs:{"variant":"text"}},[_c('user-circle-icon',{attrs:{"slot":"icon"},slot:"icon"}),_vm._v(_vm._s(_vm.userInfo.username))],1)],1)]},proxy:true}])},[_c('span',{staticStyle:{"font-size":"20px","font-weight":"700","margin-left":"20px"},attrs:{"slot":"logo"},slot:"logo"},[_vm._v("chatGPT后台管理")])])],1),_c(__unplugin_components_5,[_c(__unplugin_components_6,{staticStyle:{"border-top":"1px solid var(--component-border)","position":"fixed","z-index":"1","top":"54px","left":"0","height":"100%","border-right":"1px solid #E8E8E8"}},[_c(__unplugin_components_7,{staticStyle:{"margin-right":"50px"},attrs:{"theme":"light","router":""}},[_c(__unplugin_components_8,{attrs:{"value":"1"},on:{"click":function($event){return _vm.$router.push('/usermanage')}}},[_vm._v("用户管理")]),_c(__unplugin_components_9,{attrs:{"value":"2"},on:{"click":function($event){return _vm.$router.push('/ordermanage')}}},[_vm._v("订单管理")]),_c(__unplugin_components_10,{attrs:{"value":"3"},on:{"click":function($event){return _vm.$router.push('/commoditymanage')}}},[_vm._v("商品管理")]),_c(__unplugin_components_11,{attrs:{"value":"4"},on:{"click":function($event){return _vm.$router.push('/recommender')}}},[_vm._v("推荐人管理")])],1)],1),_c(__unplugin_components_12,{class:['content', { 'content-expand': _vm.collapsed }]},[_c('router-view',{staticClass:"animate__animated animate__fadeIn"})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }