import axios from 'axios'
import router from '@/router';
const requests = axios.create({
    baseURL:"/api",
    timeout: 5000
})
    

requests.interceptors.request.use((config)=>{
    return config;
})

requests.interceptors.response.use(response => {
    return response.data;
},
error => {
    if (error.response.status === 401) {
        router.push("/login");
        return Promise.reject(new Error(error.message));
    }
    return Promise.reject(new Error(error.message));
})

export default requests;